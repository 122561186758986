import React from "react"

import { Col, Container, Row } from "../components/grid"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Row>
        <Col>
          <h1>404 - PAGE NOT FOUND</h1>
          <p>
            You just hit a route that doesn&#39;t exist or has not been
            translated yet.
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
